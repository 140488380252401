// Global import(s)
import { ButtonGroup } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// Config
import { STRINGS, TASK_CARD_WIDTH, TASK_STATUS } from '../../../../../../utils/constants';

// Context(s)
import { usePermission } from '../../../../../../context/PermissionContext';
import { useTabs } from '../../../../../../context/TabContext';
import { useView } from '../../../../../../context/ViewContext';

// Component(s)
import ClaimTaskButton from '../shared/buttons/ClaimTaskButton';
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';
import DismissUnDismissTask from '../../../../TaskList/components/shared/DismissUnDismissTask';
import MultiMatchIndicator from '../shared/tags/MultiMatchIndicator';
import TimeCountDown from '../../../../../../components/Uplift/Countdown/TimeCountDown';
import UnclaimTaskButton from '../shared/buttons/UnclaimTaskButton';

// Utils
import JourneyUtil from '../../../../../../utils/Journey/Uplift/journeyUtil';
import toEventDate from '../../helper/toEventDate';
import toMovementDirectionLabel, { toDepartureArrivalLabel } from '../../helper/toMovementDirectionLabel';
import { getTaskId } from '../../../../../../utils/Task/taskUtil';

// Styling
import '../../__assets__/TaskHeader.scss';

const TaskHeader = ({ task, width }) => {
  const { canUpdateTask } = usePermission();
  const { DEFAULTS, isToolsTab } = useTabs();
  const { view } = useView();
  const taskId = getTaskId(task);
  const journey = JourneyUtil.get(task);
  const direction = JourneyUtil.direction(journey);
  const redirectPath = DEFAULTS?.[view]?.redirectPath;
  const canClaim = task.status === TASK_STATUS.NEW && canUpdateTask;
  const canUnClaim = task.status === TASK_STATUS.IN_PROGRESS && canUpdateTask;
  const canShowDismissUnDismiss = [TASK_STATUS.NEW, TASK_STATUS.SELECTED_FOR_DISMISSAL].includes(task.status);

  const getSpanTextClass = () => {
    if (width < TASK_CARD_WIDTH.MAXIMUM) {
      return 'ho-secondary-text-s';
    }
    return 'ho-secondary-text';
  };

  const canShowSingleLineCountdown = () => {
    return [TASK_STATUS.ISSUED, TASK_STATUS.RECEIVED, TASK_STATUS.COMPLETE]
      .includes(task.status);
  };

  const showSmallMovement = () => {
    const widthRestricted = width < 500 ? 'to-movement' : '';
    return (
      <>
        <span
          id={`movement-due-description-${taskId}`}
          className={classNames(
            'font--grey',
            getSpanTextClass(),
            widthRestricted,
          )}
        >
          {toDepartureArrivalLabel(direction)}
        </span>
        <ComponentWrapper show={width > 500 && direction && direction !== STRINGS.UNKNOWN.toUpperCase()}>
          <span>&nbsp;:&nbsp;</span>
        </ComponentWrapper>
      </>
    );
  };

  return (
    <div className="header-container__outer">
      <div className="header-container__inner">
        <div className="event-details" id={`event-details-${taskId}`}>
          <p id={`movement-due-countdown-${taskId}`}>
            <span
              id={`event-countdown-${taskId}`}
              className="ho-heading-m"
            >
              <TimeCountDown dateTime={toEventDate(journey)} />
              <ComponentWrapper show={width === TASK_CARD_WIDTH.MAXIMUM || canShowSingleLineCountdown()}>
                &nbsp;
              </ComponentWrapper>
            </span>
            {showSmallMovement()}
            <span
              id={`movement-direction-${taskId}`}
              className={classNames(
                'font--grey',
                getSpanTextClass(),
              )}
            >
              {toMovementDirectionLabel(direction)}
            </span>
          </p>
        </div>

        <div className="rule-matches-container">
          <div className="booking-multi-match">
            {/* <BookingMultiMatchIndicator /> */}
          </div>
          <div className="multi-match">
            <MultiMatchIndicator task={task} />
          </div>
        </div>

        <div className="header-actions">
          <ButtonGroup>
            <Link
              id={`${isToolsTab ? 'bulk-delete-' : ''}view-details-${taskId}`}
              aria-label={`view the task details for ${taskId}`}
              className="govuk-link ho-link-body-s grid-item"
              to={`${redirectPath}/${taskId}`}
            >
              View details
            </Link>
            <ComponentWrapper show={canShowDismissUnDismiss && !isToolsTab}>
              <DismissUnDismissTask
                className="grid-item"
                taskId={taskId}
                taskStatus={task.status}
                classModifiers="grid-item"
              />
            </ComponentWrapper>
            <ComponentWrapper show={canClaim && !isToolsTab}>
              <ClaimTaskButton
                id={`claim-task-${taskId}`}
                redirectPath={redirectPath}
                taskId={taskId}
                className="grid-item"
                classModifiers="blue grid-item"
              />
            </ComponentWrapper>
            <ComponentWrapper show={canUnClaim && !isToolsTab}>
              <UnclaimTaskButton
                id={`unclaim-task-${taskId}`}
                redirectPath={redirectPath}
                taskId={taskId}
                className="grid-item"
                classModifiers="blue grid-item"
              />
            </ComponentWrapper>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

TaskHeader.propTypes = {
  task: PropTypes.shape({}).isRequired,
  width: PropTypes.number,
};

TaskHeader.defaultProps = {
  width: 0,
};

export default memo(TaskHeader);
