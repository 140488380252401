// Global import(s)
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

// Styling
import './BVI.scss';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const BVI = ({ bookingVsIntent, className }) => {
  if (!bookingVsIntent || bookingVsIntent < 1) {
    return null;
  }

  const toBookingVsIntent = () => {
    return dayjs.duration(bookingVsIntent, 'minutes').humanize();
  };

  const bookingClassNames = classNames(className);

  return (
    <div id="booking-verses-intent">
      <span {...bookingClassNames && { className: bookingClassNames }}>BVI:&nbsp;</span>
      <span {...bookingClassNames && { className: bookingClassNames }}>{toBookingVsIntent()}</span>
    </div>
  );
};

BVI.propTypes = {
  bookingVsIntent: PropTypes.number,
  className: PropTypes.string,
};

BVI.defaultProps = {
  bookingVsIntent: 0,
  className: null,
};

export default BVI;
