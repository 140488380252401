// Config(s)
import { TAB_ID } from '../../../../utils/constants';
import DEFAULTS, { FILTER_TYPES, TASKS_TABS } from '../constants';

// Util(s)
import CommonUtil from '../../../../utils/Common/commonUtil';
import { formatInTwoDigits } from '../../../../components/Filter/Custom/Validate/utils';

export const DATE_OPTIONS = {
  IS: 'IS',
  IS_IN_DATE_RANGE: 'IS_IN_DATE_RANGE',
  IS_IN_DATE_AND_TIME_RANGE: 'IS_IN_DATE_AND_TIME_RANGE',
};

const ARRIVAL_DATE_DEPENDENCIES = {
  IS: ['arrivalIsStartDate'],
  IS_IN_DATE_RANGE: ['arrivalIsFromStartDate', 'arrivalIsToEndDate'],
  IS_IN_DATE_AND_TIME_RANGE: ['arrivalRangeIsStartDate', 'arrivalRangeIsEndDate'],
};

const DEPARTURE_DATE_DEPENDENCIES = {
  IS: ['departureIsStartDate'],
  IS_IN_DATE_RANGE: ['departureIsFromStartDate', 'departureIsToEndDate'],
  IS_IN_DATE_AND_TIME_RANGE: ['departureRangeIsStartDate', 'departureRangeIsEndDate'],
};

const CREATED_DATE_DEPENDENCIES = {
  IS: ['taskCreatedDateIsStartDate'],
  IS_IN_DATE_RANGE: ['taskCreatedDateIsFromStartDate', 'taskCreatedDateIsToEndDate'],
  IS_IN_DATE_AND_TIME_RANGE: ['taskCreatedDateRangeIsStartDate', 'taskCreatedDateRangeIsEndDate'],
};

const constructDate = (date, isEndDate) => {
  // For normal dates, without time;
  if (date.length === 3) {
    const suffix = !isEndDate ? '00:00:00Z' : '23:59:59Z';
    return `${date[2]}-${formatInTwoDigits(date[1])}-${formatInTwoDigits(date[0])}T${suffix}`;
  }

  // Default is date with time;
  const suffix = isEndDate ? ':59Z' : ':00Z';
  return `${date[2]}-${formatInTwoDigits(date[1])}-${formatInTwoDigits(date[0])}T${formatInTwoDigits(date[3])}:${formatInTwoDigits(date[4])}${suffix}`;
};

const toDate = (selection, payload, isEndDate = false) => {
  const splitDate = payload[selection]?.split('-');
  if (!splitDate || !splitDate.length) {
    return null;
  }
  const date = splitDate.filter((item) => !!item);
  if (date.length < 3) {
    return null;
  }
  return constructDate(date, isEndDate);
};

const toEndDate = (selectedOption, dateDependencies, payload) => {
  switch (selectedOption?.value) {
    case DATE_OPTIONS.IS: {
      const selection = dateDependencies.IS[0];
      return toDate(selection, payload, true);
    }
    case DATE_OPTIONS.IS_IN_DATE_RANGE: {
      const selection = dateDependencies.IS_IN_DATE_RANGE[1];
      return toDate(selection, payload, true);
    }
    case DATE_OPTIONS.IS_IN_DATE_AND_TIME_RANGE: {
      const selection = dateDependencies.IS_IN_DATE_AND_TIME_RANGE[1];
      return toDate(selection, payload, true);
    }
    default:
      return null;
  }
};

const toStartDate = (selectedOption, dateDependencies, payload) => {
  switch (selectedOption?.value) {
    case DATE_OPTIONS.IS: {
      const selection = dateDependencies.IS[0];
      return toDate(selection, payload);
    }
    case DATE_OPTIONS.IS_IN_DATE_RANGE: {
      const selection = dateDependencies.IS_IN_DATE_RANGE[0];
      return toDate(selection, payload);
    }
    case DATE_OPTIONS.IS_IN_DATE_AND_TIME_RANGE: {
      const selection = dateDependencies.IS_IN_DATE_AND_TIME_RANGE[0];
      return toDate(selection, payload);
    }
    default:
      return null;
  }
};

export const shouldIncludeIssuedByUsingId = (tabId) => {
  return [TAB_ID.ISSUED, TAB_ID.COMPLETE, TAB_ID.RECEIVED].includes(tabId);
};

export const adaptEvent = (payload) => {
  if (!payload?.arrivalDate && !payload?.departureDate) {
    return {};
  }

  const { arrivalDate, departureDate } = payload;

  return {
    inbound: {
      startDate: toStartDate(arrivalDate, ARRIVAL_DATE_DEPENDENCIES, payload),
      endDate: toEndDate(arrivalDate, ARRIVAL_DATE_DEPENDENCIES, payload),
    },
    outbound: {
      startDate: toStartDate(departureDate, DEPARTURE_DATE_DEPENDENCIES, payload),
      endDate: toEndDate(departureDate, DEPARTURE_DATE_DEPENDENCIES, payload),
    },
  };
};

export const adaptCreatedDate = (payload) => {
  if (!payload?.taskCreatedDate) {
    return {};
  }

  const { taskCreatedDate } = payload;

  return {
    startDate: toStartDate(taskCreatedDate, CREATED_DATE_DEPENDENCIES, payload),
    endDate: toEndDate(taskCreatedDate, CREATED_DATE_DEPENDENCIES, payload),
  };
};

export const toGetFilters = (movementModes, view) => {
  if (!movementModes || !Array.isArray(movementModes) || !movementModes?.length) {
    return {
      movementModes: DEFAULTS[view]?.filters.default.movementModes || [],
    };
  }

  return {
    movementModes,
  };
};

export const toGetTaskFilters = (filters, view) => {
  const defaults = DEFAULTS[view]?.filters?.default || {};

  let newFilters = {
    ...filters,
    ruleNames: filters?.rules,
    movementModes: filters?.movementModes || defaults.movementModes,
    selectors: filters?.selectors || defaults.selectors,
  };

  delete newFilters.rules;

  return newFilters;
};

export const filterParamsToRuleNames = (filters) => {
  const newFilters = {
    ...filters,
    filterParams: {
      ...filters.filterParams,
      ruleNames: filters.filterParams?.rules,
    },
  };
  delete newFilters.filterParams.rules;

  return newFilters;
};

export const filtersToRuleNames = (filters) => {
  const newFilters = {
    ...filters,
    ruleNames: filters?.rules,
  };
  delete newFilters?.rules;

  return newFilters;
};

export const adaptJourneyDirection = (payload) => {
  if (!payload?.journeyDirections?.length) {
    return [];
  }
  return payload?.journeyDirections;
};

export const adaptMovementModes = (payload, defaultFilter) => {
  if (typeof payload?.mode === 'string') {
    return [payload.mode];
  }
  if (Array.isArray(payload?.mode) && payload?.mode?.length) {
    return payload?.mode;
  }
  // Return the default
  return defaultFilter.movementModes;
};

export const adaptPassengerStatuses = (payload) => {
  if (!payload?.passengerStatuses?.length) {
    return [];
  }
  return payload?.passengerStatuses;
};

export const adaptRules = (payload, rules = null) => {
  if (rules) {
    return rules;
  }
  if (!payload?.rules?.length) {
    return [];
  }
  return payload?.rules;
};

export const adaptTargetingTeams = (payload) => {
  if (!payload?.targetingTeams?.length) {
    return [];
  }
  return payload?.targetingTeams;
};

export const toFilterPayload = (payload, defaultFilter, selectedTab, rules = null) => {
  return {
    ...payload,
    createdDate: adaptCreatedDate(payload),
    event: adaptEvent(payload),
    movementModes: adaptMovementModes(payload, defaultFilter),
    rules: adaptRules(payload, rules),
    searchText: payload?.searchText ? payload.searchText.toUpperCase().trim() : null,
    assignees: selectedTab === TAB_ID.IN_PROGRESS ? payload?.claimedByMe : [],
    journeyDirections: adaptJourneyDirection(payload),
    passengerStatuses: adaptPassengerStatuses(payload),
    targetingTeams: adaptTargetingTeams(payload),
    journeyId: payload?.journeyId ? payload.journeyId.toUpperCase().trim() : null,
    issuedBy: shouldIncludeIssuedByUsingId(selectedTab) ? payload?.claimedByMe : [],
  };
};

export const toTasksPayload = ({
  appliedFilters,
  tabIndex,
  view,
  itemsPerPage,
  offset,
  currentUser,
  taskModes,
  rules = [],
}) => {
  return {
    filterParams: {
      ...appliedFilters,
      taskStatuses: [TASKS_TABS[tabIndex].taskStatus],
      assignees: ((TASKS_TABS[tabIndex].id === TAB_ID.IN_PROGRESS)
        && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? appliedFilters?.claimedByMe : [],
      rules: rules && rules?.length > 0 ? rules : appliedFilters?.rules?.map((rule) => rule.value),
      targetingTeams: appliedFilters?.targetingTeams?.map((targetingTeam) => targetingTeam.value),
      selectedForDismissalBy: TASKS_TABS[tabIndex].id === TAB_ID.SELECTED_FOR_DISMISSAL ? [currentUser] : [],
      issuedBy: (shouldIncludeIssuedByUsingId(TASKS_TABS[tabIndex].id)
        && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? appliedFilters?.claimedByMe : [],
      taskModes,
    },
    sortParams: [
      ...DEFAULTS[view].sortParams,
    ],
    pageParams: {
      limit: itemsPerPage,
      offset,
    },
  };
};

export const toFilterCounts = (taskFilterCounts, filterParams) => {
  const getTaskStatusCounts = (item, taskStatuses) => {
    const taskStatusCounts = {
      new: 0,
      selectedForDismissal: 0,
      deleted: 0,
      inProgress: 0,
      received: 0,
      issued: 0,
      complete: 0,
    };

    taskStatusCounts[TAB_ID[taskStatuses]] = item.count;

    return taskStatusCounts;
  };

  const filterCounts = [];

  if (taskFilterCounts.selectors && Array.isArray(taskFilterCounts.selectors)) {
    taskFilterCounts.selectors?.forEach((selector) => {
      const filterCount = {
        counterType: FILTER_TYPES.SELECTORS,
        filterParams: {
          ...filterParams.filterParams,
          selectors: selector.value,
        },
        statusCounts: getTaskStatusCounts(selector, filterParams.filterParams.taskStatuses),
      };
      filterCounts.push(filterCount);
    });
  }

  taskFilterCounts.movementModes?.forEach((movementMode) => {
    const filterCount = {
      counterType: FILTER_TYPES.MODE,
      filterParams: {
        ...filterParams.filterParams,
        movementModes: [movementMode.value],
      },
      statusCounts: getTaskStatusCounts(movementMode, filterParams.filterParams.taskStatuses),
    };

    filterCounts.push(filterCount);
  });

  taskFilterCounts.journeyDirections?.forEach((direction) => {
    const filterCount = {
      counterType: FILTER_TYPES.JOURNEY_DIRECTIONS,
      filterParams: {
        ...filterParams.filterParams,
        journeyDirections: [direction.value],
      },
      statusCounts: getTaskStatusCounts(direction, filterParams.filterParams.taskStatuses),
    };

    filterCounts.push(filterCount);
  });

  taskFilterCounts.passengerStatuses?.forEach((passengerStatus) => {
    const filterCount = {
      counterType: FILTER_TYPES.PASSENGER_STATUSES,
      filterParams: {
        ...filterParams.filterParams,
        passengerStatuses: [passengerStatus.value],
      },
      statusCounts: getTaskStatusCounts(passengerStatus, filterParams.filterParams.taskStatuses),
    };

    filterCounts.push(filterCount);
  });

  taskFilterCounts.targetingTeams?.forEach((targetingTeam) => {
    const filterCount = {
      counterType: FILTER_TYPES.TARGETING_TEAMS,
      filterParams: {
        ...filterParams.filterParams,
        targetingTeams: [targetingTeam.value],
      },
      statusCounts: getTaskStatusCounts(targetingTeam, filterParams.filterParams.taskStatuses),
    };

    filterCounts.push(filterCount);
  });

  taskFilterCounts.rules?.forEach((rule) => {
    const filterCount = {
      counterType: FILTER_TYPES.RULES,
      filterParams: {
        ...filterParams.filterParams,
        rules: [rule.value],
      },
      statusCounts: getTaskStatusCounts(rule, filterParams.filterParams.taskStatuses),
    };

    filterCounts.push(filterCount);
  });

  return filterCounts;
};
