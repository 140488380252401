import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';
import dayjs from 'dayjs';
import { useWindowSize } from 'react-use';

// Config
import { DATE_FORMATS } from '../../../../../../../utils/constants';

// Component
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';
import PreviousNext from '../../generics/PreviousNext';

// Hook(s)
import { useKeycloak } from '../../../../../../../context/Keycloak';

// Util
import DateTimeUtil from '../../../../../../../utils/Datetime/datetimeUtil';
import TargetInformationUtil from '../../../../../../../utils/TargetInformation/targetInformationUtil';
import { isMobilePortrait } from '../../../../../../../utils/TargetSheet/targetSheetUtil';

import './TargetIssuedBy.scss';

const TargetIssuedBy = ({ informationSheet, preview }) => {
  const keycloak = useKeycloak();
  const { width } = useWindowSize();

  const user = TargetInformationUtil.informationSheet.targetDetails.submittingUser(informationSheet);
  const hub = TargetInformationUtil.informationSheet.targetDetails.issuingHub(informationSheet);
  const phone = TargetInformationUtil.informationSheet.targetDetails.issuingHubTel(informationSheet);

  const displayUserName = () => {
    let name = `${user?.firstName} ${user?.lastName.toUpperCase()}`;
    if (preview) {
      const [first, last] = keycloak?.tokenParsed?.name.split(' ');
      name = `${first} ${last.toUpperCase()}`;
    }

    if (phone !== null) {
      name += ` - ${phone}`;
    }

    return name;
  };

  const constructDate = (date) => {
    const tz = DateTimeUtil.timezone();
    return `on ${DateTimeUtil.timezoneFormatted(date, tz, DATE_FORMATS.CUSTOM_LONG_FULL_MONTH_AT)}`;
  };

  return (
    <div id="target-issued-by">
      <hr />
      <PreviousNext
        id="target-issued-by"
        index={0}
        size={1}
      />
      <ComponentWrapper show={!isMobilePortrait(width)}>
        <Heading id="target-issued-by" size="l">Target issued by:</Heading>
      </ComponentWrapper>
      <ComponentWrapper show={isMobilePortrait(width)}>
        <h2 id="target-issued-by" className="ho-heading-m">Target issued by:</h2>
      </ComponentWrapper>
      <p id="issued-person">
        {`${displayUserName()}`}
      </p>
      <ComponentWrapper show={!preview && informationSheet?.issuingHub?.emailAddresses !== null}>
        <p>
          {`${informationSheet?.issuingHub?.emailAddresses}`}
        </p>
      </ComponentWrapper>
      <ComponentWrapper show={hub}>
        <p>{hub}</p>
      </ComponentWrapper>
      <ComponentWrapper show={!hub}>
        <p>People Hub NBTC</p>
      </ComponentWrapper>
      <ComponentWrapper show={preview}>
        <p>
          {constructDate(dayjs.utc().format())}
        </p>
      </ComponentWrapper>
      <ComponentWrapper show={!preview}>
        <p>
          {constructDate(TargetInformationUtil.informationSheet.targetDetails.issueDate(informationSheet))}
        </p>
      </ComponentWrapper>
    </div>
  );
};

TargetIssuedBy.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
  preview: PropTypes.bool,
};

TargetIssuedBy.defaultProps = {
  preview: false,
};

export default TargetIssuedBy;
