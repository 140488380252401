// Global import(s)
import lookup from 'country-code-lookup';
import { orderBy as orderCollectionBy } from 'lodash';
import { withCount } from 'pluralise';

// Config(s)
import { LOCAL_STORAGE_KEYS, PATHS, ROLE, STRINGS, TARGETER_OUTCOME, VIEW } from '../constants';
import { SORT_ORDER } from '../../routes/Task/TaskList/constants';

// Util(s)
import { getLocalStoredItemByKeyValue, removeLocalStorageItem } from '../Storage/storageUtil';
import { shouldHideNumber, isAllHiddenInStringArray } from '../Hiding/hidingUtil';

const toOrderedCollection = (collection, orderBy, sortOrder = SORT_ORDER.ASC.toLowerCase()) => {
  if (!Array.isArray(collection) || !collection) {
    return [];
  }

  return orderCollectionBy(collection, [orderBy], [sortOrder.toLowerCase()]);
};

const sortBy = (attr) => {
  return (a, b) => {
    if (a[attr] < b[attr]) {
      return -1;
    }

    if (a[attr] > b[attr]) {
      return 1;
    }

    return 0;
  };
};

const getEntityId = (entity) => {
  if (!entity.entityId) {
    return null;
  }
  return entity.entityId;
};

const toSpaceSeparatedValue = (...values) => {
  return values.filter((value) => !!value).join(' ');
};

const toCommaSeparatedValue = (...values) => {
  return values.filter((value) => !!value).join(', ');
};

const getTabByStatus = (tabs, taskStatus) => {
  if (!tabs || !taskStatus) {
    return null;
  }

  if (!Array.isArray(tabs)) {
    return null;
  }

  if (!tabs.length) {
    return null;
  }

  const tab = tabs.find((_tab) => _tab.taskStatus === taskStatus);
  if (!tab) {
    return null;
  }

  return tab;
};

const getTabIndexByStatus = (tabs, taskStatus) => {
  if (!tabs || !taskStatus) {
    return 0;
  }

  if (!Array.isArray(tabs)) {
    return 0;
  }

  if (!tabs.length) {
    return 0;
  }

  const tabIndex = tabs.findIndex((tab) => tab.taskStatus === taskStatus);
  if (tabIndex < 0) {
    return 0;
  }

  return tabIndex;
};

const getTab = (tabs, tabIdToFind) => {
  if (!tabs || !tabIdToFind) {
    return null;
  }

  if (!Array.isArray(tabs)) {
    return null;
  }

  if (!tabs.length) {
    return null;
  }

  const tab = tabs.find((_tab) => _tab.id === tabIdToFind);
  if (!tab) {
    return null;
  }

  return tab;
};

const getTabIndex = (tabs, tabIdToFind) => {
  if (!tabs || !tabIdToFind) {
    return 0;
  }

  if (!Array.isArray(tabs)) {
    return 0;
  }

  if (!tabs.length) {
    return 0;
  }

  const tabIndex = tabs.findIndex((tab) => tab.id === tabIdToFind);
  if (tabIndex < 0) {
    return 0;
  }

  return tabIndex;
};

const formatDataFromArray = (dataSet, key) => {
  if (!dataSet || !key) {
    return null;
  }
  return dataSet.map((item) => item[key]).join(', ');
};

const shouldSeeTargetSheet = (targeterAction) => {
  return targeterAction?.outcome === TARGETER_OUTCOME.TARGET_ISSUED;
};

const hasEventAtBorderRaised = (taskData) => {
  return !!taskData?.frontLineAction?.eventAtBorderRaised;
};

const toUniqueById = (list) => {
  if (!list?.length) {
    return list;
  }

  return list.map((item, index) => {
    return {
      ...item,
      id: index,
    };
  });
};

const getListPathFromURL = (path) => {
  if (!path || typeof path !== 'string') {
    return path;
  }
  // List contains paths to be used for determining the view.
  const additionalPaths = [PATHS.PREVIOUS_TASKS.AIRPAX, PATHS.PREVIOUS_TASKS.RORO];

  return [...PATHS.TASK_LISTS(), ...PATHS.TARGET_LISTS(), ...additionalPaths].find((listPath) => path.startsWith(listPath) || path === listPath);
};

const removePreviousMovementFlag = () => {
  if (getLocalStoredItemByKeyValue(LOCAL_STORAGE_KEYS.MOVEMENT_TASK_ENABLED) !== null) {
    removeLocalStorageItem(LOCAL_STORAGE_KEYS.MOVEMENT_TASK_ENABLED);
  }
};

const removePreviousTaskFlag = () => {
  if (getLocalStoredItemByKeyValue(LOCAL_STORAGE_KEYS.PREVIOUS_TASK_ENABLED) !== null) {
    removeLocalStorageItem(LOCAL_STORAGE_KEYS.PREVIOUS_TASK_ENABLED);
  }
};

const isPreviousMovementLinkHidden = () => {
  return getLocalStoredItemByKeyValue(LOCAL_STORAGE_KEYS.MOVEMENT_TASK_ENABLED) === STRINGS.HIDDEN;
};

const isPreviousTaskLinkHidden = () => {
  return getLocalStoredItemByKeyValue(LOCAL_STORAGE_KEYS.PREVIOUS_TASK_ENABLED) === STRINGS.HIDDEN;
};

const isPreviousTaskEnabled = () => {
  return getLocalStoredItemByKeyValue(LOCAL_STORAGE_KEYS.PREVIOUS_TASK_ENABLED) !== null;
};

const getView = (path, userRole, filteredView) => {
  switch (path) {
    case PATHS.UPLIFT.PREFIX:
    case PATHS.UPLIFT.AIRPAX: {
      switch (filteredView) {
        case VIEW.IDP: {
          return VIEW.IDP;
        }
        case VIEW.COMMODITIES: {
          return VIEW.COMMODITIES;
        }
        case VIEW.OSDT: {
          return VIEW.OSDT;
        }
        case VIEW.CTBP:
        default: {
          return VIEW.CTBP;
        }
      }
    }
    case PATHS.PREVIOUS_TASKS.AIRPAX:
    case PATHS.AIRPAX: {
      return VIEW.AIRPAX;
    }
    case PATHS.TARGETS.AIRPAX: {
      switch (userRole) {
        case ROLE.RCCU: {
          return VIEW.AIRPAX_RCCU;
        }
        case ROLE.HIGHER_OFFICER: {
          return VIEW.AIRPAX_HO;
        }
        case ROLE.INTERCEPTING_OFFICER:
        default: {
          return VIEW.AIRPAX_IO;
        }
      }
    }
    case PATHS.TARGETS.RORO: {
      return VIEW.RORO_IO;
    }
    case PATHS.GENERAL_AVIATION: {
      return VIEW.GENERAL_AVIATION;
    }
    case PATHS.PREVIOUS_TASKS.RORO:
    case PATHS.RORO:
    default: {
      return VIEW.RORO;
    }
  }
};

const extractListPath = (path) => {
  return [
    ...PATHS.TASK_LISTS(),
    ...PATHS.TARGET_LISTS(),
  ]
    .filter((listPath) => path.startsWith(listPath))
    ?.[0];
};

const getViewByPathAndRole = (path, userRole = null, filteredView = null) => {
  const pathComparisons = [PATHS.RORO, PATHS.AIRPAX, PATHS.GENERAL_AVIATION, PATHS.UPLIFT.AIRPAX, PATHS.TARGETS.AIRPAX, PATHS.TARGETS.RORO];

  if (pathComparisons.some((url) => url === path || (path !== undefined && path.startsWith(url)))) {
    // This is for target list paths like /v2/tasks/airpax
    if (pathComparisons.includes(path)) {
      return getView(path, userRole, filteredView);
    }

    // This is for task details paths like /v2/tasks/airpax/DEV-12345678-123
    return getView(extractListPath(path), userRole, filteredView);
  }

  // Clean the path then get the view
  const cleanedPath = getListPathFromURL(path);
  return getView(cleanedPath, userRole);
};

const containsAssignee = (filterKey) => {
  const payload = getLocalStoredItemByKeyValue(filterKey);
  return !!payload?.claimedByMe?.length;
};

// This is also known as enrichment counts
const getMovementStats = (entity) => {
  return entity?.movementStats || undefined;
};

const getPoleV1EntitySearchURL = (entity) => {
  if (!entity?.entitySearchUrls?.poleV1Url) {
    return null;
  }

  return entity?.entitySearchUrls?.poleV1Url;
};

const getSipEntitySearchURL = (entity) => {
  if (!entity?.entitySearchUrls?.sip) {
    return null;
  }

  return entity.entitySearchUrls.sip;
};

const getCentaurEntitySearchURL = (entity) => {
  if (!entity?.entitySearchUrls?.centaur) {
    return null;
  }

  return entity.entitySearchUrls.centaur;
};

const getEntitySearchURL = (entity) => {
  if (!entity) {
    return null;
  }

  return getCentaurEntitySearchURL(entity) || getSipEntitySearchURL(entity) || null;
};

const getNumberOfPreviousTasks = (entity) => {
  if (shouldHideNumber(entity?.numberOfPreviousTasks)) {
    return null;
  }
  return entity?.numberOfPreviousTasks ?? 0;
};

const getTelephoneContact = (entity) => {
  if (!entity || !entity?.contacts?.phone) {
    return null;
  }

  return entity.contacts.phone;
};

const getMobileContact = (entity) => {
  if (!entity || !entity?.contacts?.mobile) {
    return null;
  }

  return entity.contacts.mobile;
};

const getTelephoneContactNumber = (entity) => {
  if (!entity || !entity?.contacts?.phoneNumber) {
    return null;
  }

  return entity.contacts.phoneNumber;
};

const getMobileContactNumber = (entity) => {
  if (!entity || !entity?.contacts?.mobileNumber) {
    return null;
  }

  return entity.contacts.mobileNumber;
};

const getAddress = (entity) => {
  return entity?.address || null;
};

const getTotalNumberOfPreviousTasks = (entities) => {
  const totalNumberOfPreviousTasks = entities.map((entity) => getNumberOfPreviousTasks(entity));

  if (!totalNumberOfPreviousTasks?.length) {
    return 0;
  }

  if (isAllHiddenInStringArray(totalNumberOfPreviousTasks)) {
    return null;
  }

  return totalNumberOfPreviousTasks.reduce((prev, next) => prev + next, 0);
};

const getPreviousTaskLinkUrl = (params) => {
  const entity = params.entity;
  const numberOfPreviousTasks = getNumberOfPreviousTasks(entity);
  if (numberOfPreviousTasks < 1) {
    return null;
  }
  const movementId = params.movementId;
  const entityId = entity.entityId;
  return `/previous-tasks?movement-id=${encodeURIComponent(movementId)}`
  + `&entity-type=${entityId.type}&svx-id=${entityId.svxId || null}&pole-v1-id=${entityId.poleV1Id || null}`
  + `&pole-v2-id=${encodeURIComponent(entityId.poleV2Id || null)}`;
};

const getPreviousTaskLinkText = (entity) => {
  const numberOfPreviousTasks = getNumberOfPreviousTasks(entity);
  return withCount(numberOfPreviousTasks, '% previous task', '% previous tasks', '');
};

const getPreviousMovementsLinkText = (movementCount) => {
  return withCount(movementCount, 'other movement', 'other movements');
};

const convertToIso3Code = (iso2Code) => {
  if (!iso2Code || iso2Code === STRINGS.UNKNOWN) {
    return STRINGS.UNKNOWN;
  }
  try {
    if (!lookup?.byIso(iso2Code)) {
      return STRINGS.UNKNOWN;
    }
    return lookup?.byIso(iso2Code)?.iso3 || STRINGS.UNKNOWN;
  } catch {
    return STRINGS.UNKNOWN;
  }
};

const stripPageDetailsFromURL = (str) => {
  const len = str.match(/v2/g) ? 3 : 2;
  const parts = str.split('/').filter(Boolean);
  return `/${parts.slice(0, len).join('/')}`;
};

const CommonUtil = {
  address: getAddress,
  centaurEntitySearchURL: getCentaurEntitySearchURL,
  commaSeparated: toCommaSeparatedValue,
  eabRaised: hasEventAtBorderRaised,
  entityId: getEntityId,
  entitySearchUrl: getEntitySearchURL,
  findTab: getTab,
  findTabByStatus: getTabByStatus,
  findTabIndex: getTabIndex,
  findTabIndexByStatus: getTabIndexByStatus,
  getNumberOfPreviousTasks,
  getPreviousMovementsLinkText,
  getPreviousTaskLinkText,
  getPreviousTaskLinkUrl,
  getTotalNumberOfPreviousTasks,
  hasAssignee: containsAssignee,
  isPreviousMovementLinkHidden,
  isPreviousTaskEnabled,
  isPreviousTaskLinkHidden,
  iso3Code: convertToIso3Code,
  mobileContact: getMobileContact,
  mobileContactNumber: getMobileContactNumber,
  movementStats: getMovementStats,
  orderCollection: toOrderedCollection,
  poleV1EntitySearchUrl: getPoleV1EntitySearchURL,
  removeMovementFlag: removePreviousMovementFlag,
  removePreviousFlag: removePreviousTaskFlag,
  requiresTargetSheet: shouldSeeTargetSheet,
  sipEntitySearchURL: getSipEntitySearchURL,
  spaceSeparated: toSpaceSeparatedValue,
  strip: stripPageDetailsFromURL,
  getListPath: getListPathFromURL,
  telephoneContact: getTelephoneContact,
  telephoneContactNumber: getTelephoneContactNumber,
  uniqueId: toUniqueById,
  viewByPathAndRole: getViewByPathAndRole,
  informationSheet: {
    formatArrayData: formatDataFromArray,
  },
  sorter: {
    by: sortBy,
  },
};

export default CommonUtil;

export {
  containsAssignee,
  convertToIso3Code,
  formatDataFromArray,
  getAddress,
  getCentaurEntitySearchURL,
  getEntityId,
  getEntitySearchURL,
  getMobileContact,
  getMobileContactNumber,
  getMovementStats,
  getSipEntitySearchURL,
  getTab,
  getTabByStatus,
  getTabIndex,
  getTabIndexByStatus,
  getListPathFromURL,
  getTelephoneContact,
  getTelephoneContactNumber,
  getViewByPathAndRole,
  hasEventAtBorderRaised,
  shouldSeeTargetSheet,
  sortBy,
  stripPageDetailsFromURL,
  toCommaSeparatedValue,
  toOrderedCollection,
  toSpaceSeparatedValue,
  toUniqueById,
};
