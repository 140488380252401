// Global import(s)
import { Tag } from '@ukhomeoffice/cop-react-components';
import * as pluralise from 'pluralise';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useWindowSize } from 'react-use';

// Config
import { DEPARTURE_STATUS, TARGET_SHEET_WIDTH } from '../../../../../../../utils/constants';

// Component(s)
import AdditionalContent from '../../content/AdditionalContent';
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';
import ExtendedListRow from '../../generics/ExtendedListRow';
import Photo from './Photo';
import PncCheck from '../../checks/PncCheck';
import SystemChecksCompleted from '../../checks/SystemChecksCompleted';

// Util(s)
import {
  TargetCommonUtil,
  CommonUtil,
  CredibilityChecksUtil,
  DateTimeUtil,
  TargetPersonUtil,
} from '../../../../../../../utils';
import DocumentUtil from '../../../../../../../utils/Document/Uplift/documentUtil';
import { isMobilePortrait } from '../../../../../../../utils/TargetSheet/targetSheetUtil';

// Styling
import './PersonDetails.scss';

const PersonDetails = ({ id, person, personPosition, mode, preview }) => {
  // Preview target sheet check; also helps with the PNR only target sheet;
  if (!person) {
    return null;
  }

  const adjustedPersonPosition = personPosition + 1;
  const checksCompleted = CredibilityChecksUtil.getChecksWithoutPnc(person);
  const document = TargetPersonUtil.document(person);
  const iso2CountryOfIssueCode = DocumentUtil.countryOfIssue(document);
  const iso3CountryOfIssueCode = CommonUtil.iso3Code(iso2CountryOfIssueCode);
  const formattedCountryOfIssue = DocumentUtil.format.countryOfIssue(iso2CountryOfIssueCode);
  const personAdditionalContents = TargetCommonUtil.additionalTextContents(person);
  const documentAdditionalContents = TargetCommonUtil.additionalTextContents(document);
  const photoContent = TargetCommonUtil.additionalPhotoContent(person);
  const baggageAdditionalContents = TargetCommonUtil.additionalTextContents(person.baggage);
  const { width } = useWindowSize();

  const constructFullName = () => {
    let firstName = person?.name?.first
      .toLowerCase()
      .split(' ')
      .map((n) => n.charAt(0).toUpperCase() + n.slice(1))
      .join(' ');
    return `${firstName} ${person?.name?.last.toUpperCase()}`;
  };

  const getMovementStatusTag = () => {
    if (!person?.passengerStatus) {
      return null;
    }

    const textType = () => {
      if (width < TARGET_SHEET_WIDTH) {
        return DEPARTURE_STATUS[person?.passengerStatus]?.code;
      }
      return DEPARTURE_STATUS[person?.passengerStatus]?.description;
    };

    return (
      <Tag
        id="summary-movement-description"
        className="tag tag--blue"
        text={textType()}
      />
    );
  };

  const getNationality = () => {
    return `${formattedCountryOfIssue} - ${iso3CountryOfIssueCode}`;
  };

  return (
    <div id="person-details" className="govuk-grid-row target-info-summary-list">
      <div className="govuk-grid-column-full">
        <div id="person-details-container" className="govuk-grid-row">
          <div id="person-details-display">
            <ComponentWrapper show={isMobilePortrait(width)}>
              <ExtendedListRow
                activeClass="badge-enabled"
                id={`person-${person.dateOfBirth}`}
                type="header-first"
                values={[
                  constructFullName(),
                  getMovementStatusTag(),
                ]}
              />
              <ExtendedListRow
                id={`person-${person.dateOfBirth}-${person?.seatNumber}-nationality`}
                mobile
                type="body"
                values={[
                  ['Nationality', getNationality()],
                  ['Date of birth', DateTimeUtil.format(person?.dateOfBirth, 'DD MMM YYYY')],
                ]}
              />
              <ExtendedListRow
                id={`person-${person.dateOfBirth}-${person?.seatNumber}-gender`}
                mobile
                type="body"
                values={[
                  ['Gender', person?.gender?.name],
                  ['Seat number', person?.seatNumber],
                ]}
              />
              <ComponentWrapper show={photoContent}>
                <div className="person-photo">
                  <Photo
                    personPosition={adjustedPersonPosition}
                    photoContent={photoContent}
                  />
                </div>
              </ComponentWrapper>
              <AdditionalContent additions={personAdditionalContents} heading="Addition" />
              <ExtendedListRow
                id={`person-${person?.dateOfBirth}-passport`}
                mobile
                type="header"
                values={[
                  'Passport',
                  person?.document?.number,
                ]}
              />
              <ExtendedListRow
                id={`person-${person?.dateOfBirth}-issued`}
                mobile
                type="body"
                values={[
                  ['Issued by', `${formattedCountryOfIssue} - ${iso3CountryOfIssueCode}`],
                  '',
                ]}
              />
              <ExtendedListRow
                id={`person-${person?.dateOfBirth}-issued`}
                mobile
                type="body"
                values={[
                  ['Valid to', DateTimeUtil.format(person?.document?.expiry, 'DD MMM YYYY')],
                  ['Issue date', DateTimeUtil.format(person?.document?.validFrom, 'DD MMM YYYY')],
                ]}
              />
              <AdditionalContent additions={documentAdditionalContents} heading="Addition" />
              <ExtendedListRow
                id={`person-${person?.dateOfBirth}-baggage-details-header`}
                mobile
                type="header"
                values={[
                  'Baggage',
                  pluralise.withCount(person?.baggage?.numberOfCheckedBags, '% bag', '%bags'),
                ]}
              />
              <ExtendedListRow
                id={`person-${person?.dateOfBirth}-baggage-details`}
                mobile
                type="body"
                values={[
                  ['Weight', `${person?.baggage?.weight} total`],
                  ['Tag numbers', person?.baggage?.tags],
                ]}
              />
              <AdditionalContent additions={baggageAdditionalContents} heading="Addition" />
            </ComponentWrapper>
            <ComponentWrapper show={!isMobilePortrait(width)}>
              <ExtendedListRow
                activeClass="badge-enabled"
                id={`person-${person.dateOfBirth}`}
                type="header-first"
                values={[
                  constructFullName(),
                  '',
                  `Task ID: ${id}`,
                  getMovementStatusTag(),
                ]}
              />
              <ExtendedListRow
                id={`person-${person.dateOfBirth}-${person?.seatNumber}`}
                type="body"
                values={[
                  ['Nationality', getNationality()],
                  ['Date of birth', DateTimeUtil.format(person?.dateOfBirth, 'DD MMM YYYY')],
                  ['Gender', person?.gender?.name],
                  ['Seat number', person?.seatNumber],
                ]}
              />
              <AdditionalContent additions={personAdditionalContents} heading="Addition" />
              <ExtendedListRow
                id={`person-${person?.dateOfBirth}-passport`}
                type="header"
                values={[
                  'Passport',
                  person?.document?.number,
                  '',
                  '',
                ]}
              />
              <ExtendedListRow
                id={`person-${person?.dateOfBirth}-issued`}
                type="body"
                values={[
                  ['Issued by', `${formattedCountryOfIssue} - ${iso3CountryOfIssueCode}`],
                  ['Issue date', DateTimeUtil.format(person?.document?.validFrom, 'DD MMM YYYY')],
                  ['Valid to', DateTimeUtil.format(person?.document?.expiry, 'DD MMM YYYY')],
                  '',
                ]}
              />
              <AdditionalContent additions={documentAdditionalContents} heading="Addition" />
              <ComponentWrapper show={person?.baggage?.numberOfCheckedBags > 0 && person?.baggage?.weight > 0}>
                <ExtendedListRow
                  id={`person-${person?.dateOfBirth}-baggage-details`}
                  type="header"
                  values={[
                    'Baggage',
                    (person?.baggage?.numberOfCheckedBags > 0
                      ? pluralise.withCount(person?.baggage?.numberOfCheckedBags, '% bag', '%bags') : ''),
                    (person?.baggage?.weight > 0 ? `${person?.baggage?.weight} total` : ''),
                    person?.baggage?.tags,
                  ]}
                />
              </ComponentWrapper>
              <AdditionalContent additions={baggageAdditionalContents} heading="Addition" />
            </ComponentWrapper>
          </div>
          <ComponentWrapper show={photoContent && !isMobilePortrait(width)}>
            <div className="person-photo">
              <Photo personPosition={adjustedPersonPosition} photoContent={photoContent} />
            </div>
          </ComponentWrapper>
        </div>
        <div id="checks">
          <div id="left">
            <PncCheck
              person={person}
              personPosition={personPosition}
              mode={mode}
              preview={preview}
            />
          </div>
          <div id="right">
            <SystemChecksCompleted
              checksCompleted={checksCompleted}
              preview={preview}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PersonDetails.propTypes = {
  id: PropTypes.string.isRequired,
  person: PropTypes.shape({}).isRequired,
  personPosition: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

PersonDetails.defaultProps = {
  preview: false,
};

export default memo(PersonDetails);
