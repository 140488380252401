// Global import(s)
import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

// Config
import { TASK_CARD_WIDTH } from '../../../../../../../utils/constants';

// Context(s)
import { useNotification } from '../../../../../../../context/NotificationContext';
import { useTasks } from '../../../../../../../context/TasksContext';

// Component(s)
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';
import ModeTaskDetail from './ModeTaskDetail';
import ModeTaskHeader from './ModeTaskHeader';
import TaskNotification from '../../../../../TaskList/components/shared/TaskNotification';

// Hook
import useClientRect from '../../../../../../../utils/Hooks/useClientRect';

// Styling
import './TaskCard.scss';

const TaskCard = ({ task, ...props }) => {
  const { notification } = useNotification();
  const { taskIdsToRemove } = useTasks();
  const ref = useRef(null);
  const { rect } = useClientRect(ref);
  const { width } = rect;
  const [taskCardWidth, setTaskCardWidth] = useState(width);
  const taskStatus = task.status;

  const getTaskCardClass = () => {
    if (taskCardWidth < TASK_CARD_WIDTH.MINIMUM) {
      return 'task-card-minimum';
    }
    if (taskCardWidth >= TASK_CARD_WIDTH.MINIMUM && taskCardWidth < TASK_CARD_WIDTH.STANDARD) {
      return 'task-card-750';
    }
    return 'task-card-maximum';
  };

  const getTaskCardStatusClass = () => {
    return `task-card-${taskStatus.toLowerCase()}`;
  };

  useEffect(() => {
    if (width !== taskCardWidth) {
      setTaskCardWidth(width);
    }
  }, [width]);

  return (
    <div
      ref={ref}
      {...props}
      className={classNames(
        'task-card',
        getTaskCardClass(),
        getTaskCardStatusClass(),
      )}
    >
      <ComponentWrapper show={notification && taskIdsToRemove.includes(task.id)}>
        <TaskNotification />
      </ComponentWrapper>
      <ModeTaskHeader task={task} width={taskCardWidth} />
      <ModeTaskDetail task={task} width={taskCardWidth} />
    </div>
  );
};

TaskCard.propTypes = {
  task: PropTypes.shape({}).isRequired,
};

export default memo(TaskCard);
