// Global import(s)
import PropTypes from 'prop-types';
import { Heading, Tag } from '@ukhomeoffice/cop-react-components';
import * as pluralise from 'pluralise';
import React from 'react';
import { useWindowSize } from 'react-use';

// Config
import { DEPARTURE_STATUS, TARGET_SHEET_WIDTH } from '../../../../../../utils/constants';

// Component
import AdditionalContent from '../content/AdditionalContent';
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';
import ExtendedListRow from '../generics/ExtendedListRow';
import Photo from './airpax/Photo';
import PncCheck from '../checks/PncCheck';
import PreviousNext from '../generics/PreviousNext';
import SystemChecksCompleted from '../checks/SystemChecksCompleted';

// Util
import {
  CommonUtil,
  CredibilityChecksUtil,
  DateTimeUtil,
  TargetCommonUtil,
} from '../../../../../../utils';
import DocumentUtil from '../../../../../../utils/Document/Uplift/documentUtil';
import TargetPersonUtil from '../../../../../../utils/Person/Target/targetPersonUtil';
import { isMobilePortrait } from '../../../../../../utils/TargetSheet/targetSheetUtil';

// Styling
import './CoTravellers.scss';

const CoTravellers = ({ informationSheet, mode, preview }) => {
  const { width } = useWindowSize();

  const coTravellers = TargetPersonUtil.coTravellers(informationSheet);
  if (!coTravellers || coTravellers.length < 1) {
    return null;
  }

  const constructFullName = (person) => {
    let firstName = person?.name?.first
      .toLowerCase()
      .split(' ')
      .map((n) => n.charAt(0).toUpperCase() + n.slice(1))
      .join(' ');
    return `${firstName} ${person?.name?.last.toUpperCase()}`;
  };

  const getMovementStatusTag = (person) => {
    if (!person?.passengerStatus) {
      return null;
    }

    const textType = () => {
      if (width < TARGET_SHEET_WIDTH) {
        return DEPARTURE_STATUS[person?.passengerStatus]?.code;
      }
      return DEPARTURE_STATUS[person?.passengerStatus]?.description;
    };

    return (
      <Tag
        id="summary-movement-description"
        className="tag tag--blue"
        text={textType()}
      />
    );
  };

  return (
    <div id="co-travellers" className="govuk-grid-row target-info-summary-list">
      <hr />
      <ComponentWrapper show={coTravellers.length > 0}>
        <ComponentWrapper show={!isMobilePortrait(width)}>
          <Heading id="co-travellers-header" size="l">Co-travellers</Heading>
        </ComponentWrapper>
        <ComponentWrapper show={isMobilePortrait(width)}>
          <h2 id="co-travellers-header" className="ho-heading-m">Co-travellers</h2>
        </ComponentWrapper>
      </ComponentWrapper>
      {coTravellers?.map((coTraveller, index) => {
        const personAdditionalContents = TargetCommonUtil.additionalTextContents(coTraveller);
        const checksCompleted = CredibilityChecksUtil.getChecksWithoutPnc(coTraveller);
        const document = TargetPersonUtil.document(coTraveller);
        const iso2CountryOfIssueCode = DocumentUtil.countryOfIssue(document);
        const iso3CountryOfIssueCode = CommonUtil.iso3Code(iso2CountryOfIssueCode);
        const formattedCountryOfIssue = DocumentUtil.format.countryOfIssue(iso2CountryOfIssueCode);
        const photoContent = TargetCommonUtil.additionalPhotoContent(coTraveller);
        const documentAdditionalContents = TargetCommonUtil.additionalTextContents(document);

        const getNationality = () => {
          return `${formattedCountryOfIssue} - ${iso3CountryOfIssueCode}`;
        };

        return (
          <div
            className="govuk-grid-row target-info-summary-list"
            id={`co-traveller-${index}`}
            key={`co-traveller-${index}`}
          >
            <div className="govuk-grid-column-full">
              <PreviousNext
                id="co-travellers"
                index={index}
                size={coTravellers.length}
              />
              <Heading size="s">Co-traveller {index + 1}</Heading>
              <div className="co-travellers-container govuk-grid-row">
                <div className="co-travellers-display">
                  <ComponentWrapper show={!isMobilePortrait(width)}>
                    <ExtendedListRow
                      activeClass="badge-enabled"
                      id={`person-${coTraveller?.dateOfBirth}`}
                      type="header-first"
                      values={[
                        constructFullName(coTraveller),
                        '',
                        `Task ID: ${informationSheet?.id}`,
                        getMovementStatusTag(coTraveller),
                      ]}
                    />
                    <ExtendedListRow
                      id={`person-${coTraveller?.dateOfBirth}-${coTraveller?.seatNumber}`}
                      type="body"
                      values={[
                        ['Nationality', getNationality()],
                        ['Date of birth', DateTimeUtil.format(coTraveller?.dateOfBirth, 'DD MMM YYYY')],
                        ['Gender', coTraveller?.gender?.name],
                        ['Seat number', coTraveller?.seatNumber],
                      ]}
                    />
                    <AdditionalContent additions={personAdditionalContents} heading="Addition" />
                    <ExtendedListRow
                      id={`person-${coTraveller?.dateOfBirth}-passport`}
                      type="header"
                      values={[
                        'Passport',
                        coTraveller?.document?.number,
                        '',
                        '',
                      ]}
                    />
                    <ExtendedListRow
                      id={`person-${coTraveller?.dateOfBirth}-issued`}
                      type="body"
                      values={[
                        ['Issued by', `${formattedCountryOfIssue} - ${iso3CountryOfIssueCode}`],
                        ['Issue date', DateTimeUtil.format(coTraveller?.document?.issueCountry?.issueDate || DateTimeUtil.date(), 'DD MMM YYYY')],
                        ['Valid to', DateTimeUtil.format(coTraveller?.document?.expiry, 'DD MMM YYYY')],
                        '',
                      ]}
                    />
                    <AdditionalContent additions={documentAdditionalContents} heading="Addition" />
                    <ComponentWrapper show={coTraveller?.baggage?.numberOfCheckedBags > 0 && coTraveller?.baggage?.weight > 0}>
                      <ExtendedListRow
                        id={`person-${coTraveller?.dateOfBirth}-baggage-details`}
                        type="header"
                        values={[
                          'Baggage',
                          (coTraveller?.baggage?.numberOfCheckedBags > 0
                            ? pluralise.withCount(coTraveller?.baggage?.numberOfCheckedBags, '% bag', '%bags') : ''),
                          (coTraveller?.baggage?.weight > 0 ? `${coTraveller?.baggage?.weight} total` : ''),
                          coTraveller?.baggage?.tags,
                        ]}
                      />
                    </ComponentWrapper>
                  </ComponentWrapper>
                  <ComponentWrapper show={isMobilePortrait(width)}>
                    <ExtendedListRow
                      activeClass="badge-enabled"
                      id={`person-${coTraveller?.dateOfBirth}`}
                      mobile
                      type="header-first"
                      values={[
                        constructFullName(coTraveller),
                        getMovementStatusTag(coTraveller),
                      ]}
                    />
                    <ExtendedListRow
                      id={`person-${coTraveller?.dateOfBirth}-${coTraveller?.seatNumber}-nationality`}
                      mobile
                      type="body"
                      values={[
                        ['Nationality', getNationality()],
                        ['Date of birth', DateTimeUtil.format(coTraveller?.dateOfBirth, 'DD MMM YYYY')],
                      ]}
                    />
                    <ExtendedListRow
                      id={`person-${coTraveller?.dateOfBirth}-${coTraveller?.seatNumber}-gender`}
                      mobile
                      type="body"
                      values={[
                        ['Gender', coTraveller?.gender?.name],
                        ['Seat number', coTraveller?.seatNumber],
                      ]}
                    />
                    <ComponentWrapper show={photoContent}>
                      <div className="person-photo">
                        <Photo
                          personPosition={index}
                          photoContent={photoContent}
                        />
                      </div>
                    </ComponentWrapper>
                    <AdditionalContent additions={personAdditionalContents} heading="Addition" />
                    <ExtendedListRow
                      id={`person-${coTraveller?.dateOfBirth}-passport`}
                      mobile
                      type="header"
                      values={[
                        'Passport',
                        coTraveller?.document?.number,
                      ]}
                    />
                    <ExtendedListRow
                      id={`person-${coTraveller?.dateOfBirth}-issued`}
                      mobile
                      type="body"
                      values={[
                        ['Issued by', `${formattedCountryOfIssue} - ${iso3CountryOfIssueCode}`],
                        '',
                      ]}
                    />
                    <ExtendedListRow
                      id={`person-${coTraveller?.dateOfBirth}-valid-to`}
                      mobile
                      type="body"
                      values={[
                        ['Valid to', DateTimeUtil.format(coTraveller?.document?.expiry, 'DD MMM YYYY')],
                        ['Issue date', DateTimeUtil.format(coTraveller?.document?.issueCountry?.issueDate || DateTimeUtil.date(), 'DD MMM YYYY')],
                      ]}
                    />
                    <AdditionalContent additions={documentAdditionalContents} heading="Addition" />
                    <ComponentWrapper show={coTraveller?.baggage !== null}>
                      <ExtendedListRow
                        id={`person-${coTraveller?.dateOfBirth}-baggage-details`}
                        mobile
                        type="header"
                        values={[
                          'Baggage',
                          pluralise.withCount(coTraveller?.baggage?.numberOfCheckedBags, '% bag', '%bags'),
                        ]}
                      />
                      <ExtendedListRow
                        id={`person-${coTraveller?.dateOfBirth}-baggage-total`}
                        mobile
                        type="body"
                        values={[
                          `${coTraveller?.baggage?.weight} total`,
                          coTraveller?.baggage?.tags,
                        ]}
                      />
                    </ComponentWrapper>
                  </ComponentWrapper>
                </div>
                <ComponentWrapper show={photoContent && !isMobilePortrait(width)}>
                  <div className="person-photo">
                    <Photo personPosition={index} photoContent={photoContent} />
                  </div>
                </ComponentWrapper>
              </div>
              <div id="checks">
                <div id="left">
                  <PncCheck
                    person={coTraveller}
                    personPosition={index + 1}
                    mode={mode}
                    preview={preview}
                  />
                </div>
                <div id="right">
                  <SystemChecksCompleted checksCompleted={checksCompleted} preview={preview} />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

CoTravellers.propTypes = {
  informationSheet: PropTypes.shape({}).isRequired,
  mode: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

CoTravellers.defaultProps = {
  preview: false,
};

export default CoTravellers;
