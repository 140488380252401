// Config(s)
import { COMPONENT_IDS, FRONTLINE_CHECK_OPTION_VALUES } from '../constants';
import { STRINGS, SUB_MODES } from '../../../../../../../../utils/constants';

// Util
import { isGaSubMode } from '../../../../../../../../utils/Task/taskUtil';

const toPublicInterestImmunity = (formData) => {
  const value = formData?.[COMPONENT_IDS.PUBLIC_INTEREST_IMMUNITY];

  if (value === undefined || (Array.isArray(value) && !value?.length)) {
    return null;
  }

  return !!value;
};

const toSuspectedAbuseTypes = (formData, subMode) => {
  const abuseTypes = formData[COMPONENT_IDS.ABUSE_TYPES];

  if (!abuseTypes?.length || isGaSubMode(subMode)) {
    return null;
  }

  return abuseTypes.map((abuseType) => {
    if (abuseType === STRINGS.FORM.OTHER) {
      return `${abuseType} - ${formData[COMPONENT_IDS.OTHER_TYPES]}`;
    }
    return abuseType;
  });
};

const toWatchlist = (formData) => {
  const watchlist = formData[COMPONENT_IDS.WATCHLIST];

  if (watchlist === undefined) {
    return false;
  }

  return watchlist === 'true';
};

// TODO: Update the return for general aviation when multiple
// recipients is enabled;
const toRecipients = (formData, subMode) => {
  if (subMode === SUB_MODES.GENERAL_AVIATION) {
    return {
      recipients: {
        type: 'GENERAL_AVIATION',
        group: formData[COMPONENT_IDS.RECIPIENTS][0],
      },
    };
  }

  // #18228 Change this when the BE has the groups shape ready
  // for us;
  if (subMode === SUB_MODES.IDP) {
    return {
      recipients: {
        type: 'AIRPAX_IDP',
        groups: formData[COMPONENT_IDS.RECIPIENTS],
      },
    };
  }
  return {
    recipients: {
      type: 'AIRPAX_COMMODITIES',
      groups: formData[COMPONENT_IDS.RECIPIENTS],
      users: formData[COMPONENT_IDS.BORDER_OFFICERS],
    },
  };
};

const toFrontLineChecks = (formData, subMode) => {
  if (!isGaSubMode(subMode)) {
    return null;
  }

  const cleanOtherFrontLineCheck = (enabledComponent, enabledOption, key) => {
    const enabled = formData?.[enabledComponent]?.includes(enabledOption);
    const check = formData?.[key];

    return (enabled && check && [check]) || [];
  };

  const cleanFrontLineChecks = (enabledOption, key) => {
    const enabled = formData?.[COMPONENT_IDS.FRONTLINE_CHECKS]?.includes(enabledOption);
    const checks = formData?.[key];

    if (!enabled) {
      return { [key]: [] };
    }

    return {
      [key]: [
        ...checks?.filter((check) => check !== 'other') || [],
        ...cleanOtherFrontLineCheck(key, 'other', `${key}OtherDetail`),
      ],
    };
  };

  return {
    ...cleanFrontLineChecks(
      FRONTLINE_CHECK_OPTION_VALUES.IMMIGRATION_CHECKS,
      COMPONENT_IDS.IMMIGRATION,
    ),
    ...cleanFrontLineChecks(
      FRONTLINE_CHECK_OPTION_VALUES.CUSTOMS_CHECKS,
      COMPONENT_IDS.CUSTOMS,
    ),
    ...cleanFrontLineChecks(
      FRONTLINE_CHECK_OPTION_VALUES.INTELLIGENCE_GATHERING_CHECKS,
      COMPONENT_IDS.INTELLIGENCE,
    ),
    ...cleanFrontLineChecks(
      FRONTLINE_CHECK_OPTION_VALUES.CREW_CHECKS,
      COMPONENT_IDS.CREW,
    ),
    additionalChecks: cleanOtherFrontLineCheck(
      COMPONENT_IDS.FRONTLINE_CHECKS,
      FRONTLINE_CHECK_OPTION_VALUES.ADDITIONAL_CHECKS,
      COMPONENT_IDS.ADDITIONAL,
    ),
  };
};

const toPnrElements = (formData) => {
  return {
    selectedPnrElements: formData[COMPONENT_IDS.PNR_ELEMENTS] || null,
  };
};

const toSubmissionPayload = (taskId, formData, additionalContent, credibilityChecks, subMode = 'AIRPAX_COMMODITIES') => {
  return {
    taskId,
    target: {
      ...toRecipients(formData, subMode),
      ...toFrontLineChecks(formData, subMode),
      suspectedAbuseTypes: toSuspectedAbuseTypes(formData, subMode),
      referralReason: formData[COMPONENT_IDS.REFERRAL_REASON],
      category: formData[COMPONENT_IDS.TARGET_CATEGORY],
      operation: formData[COMPONENT_IDS.OPERATION] || null,
      publicInterestImmunity: toPublicInterestImmunity(formData),
      issuingHub: {
        name: '', // TODO: Update this once a new design has been provided for create target.
        telephone: '', // TODO: Update this once a new design has been provided for create target.
      },
      addedToWatchList: toWatchlist(formData),
      ...toPnrElements(formData),
    },
    task: {
      additionalContent: {
        add: additionalContent ? [...additionalContent] : null,
      },
      credibilityChecks,
    },
  };
};

export default toSubmissionPayload;
