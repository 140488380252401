// Global import(s)
import { Link } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { useWindowSize } from 'react-use';

// Config(s)
import config from '../../../../../../../utils/config';

// Component
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';
import LoadingSpinner from '../../../../../../../components/LoadingSpinner/LoadingSpinner';

// Hook(s)
import useFetchImage from '../../../../../../../utils/Hooks/useFetchImage';

// Util
import { isMobilePortrait } from '../../../../../../../utils/TargetSheet/targetSheetUtil';

// Styling
import './Photo.scss';

const Photo = ({ personPosition, photoContent }) => {
  const { url, isLoading } = useFetchImage(photoContent?.url);
  const { width } = useWindowSize();

  useEffect(() => {
    return () => URL.revokeObjectURL(url);
  }, []);

  if (!url) {
    return null;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="target-photo">
      <ComponentWrapper show={!isMobilePortrait(width)}>
        <Link
          aria-label={`Taken ${photoContent?.approximateDateTaken}`}
          href={url}
          target="_blank"
          title="Link to photo"
        >
          <img
            alt={`Taken ${photoContent?.approximateDateTaken}`}
            className="passport-photo hods-file-upload__thumb"
            src={url}
          />
        </Link>
      </ComponentWrapper>
      <ComponentWrapper show={isMobilePortrait(width)}>
        <img
          alt={`Taken ${photoContent?.approximateDateTaken}`}
          className="passport-photo hods-file-upload__thumb"
          src={url}
        />
      </ComponentWrapper>
      <ComponentWrapper show={photoContent && config.enableUpliftTargetSheet}>
        <div>
          <div id={`passenger-${personPosition}-photo-date-label`}>
            <span>Date of photo:</span> {photoContent?.approximateDateTaken}
          </div>
        </div>
      </ComponentWrapper>
    </div>
  );
};

Photo.propTypes = {
  personPosition: PropTypes.number,
  photoContent: PropTypes.shape({
    url: PropTypes.string,
    source: PropTypes.string,
    filename: PropTypes.string,
    approximateDateTaken: PropTypes.string,
  }),
};

Photo.defaultProps = {
  personPosition: 0,
  photoContent: null,
};

export default memo(Photo);
